import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import {Layout, PostCard} from '../components/common'
import { MetaData } from '../components/common/meta'
import HyvorTalk from 'hyvor-talk-react'
import {readingTime as readingTimeHelper} from "@tryghost/helpers";
import config from "../utils/siteConfig";
import TableOfContents from "gatsby-theme-ghost-toc/src/gatsby-theme-try-ghost/components/common/TableOfContents.js";

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, pageContext }) => {
    const post = data.ghostPost
    const toc = (post.childHtmlRehype && post.childHtmlRehype.tableOfContents) || []
    const transformedHtml = (post.childHtmlRehype && post.childHtmlRehype.html) || post.html
    const readingTime = readingTimeHelper(post)
    const url = `/${post.slug}/`
    const welcomePage = post.title === 'Welcome'
    const byLineClass = welcomePage ? "hide" : "post-full-byline";
    const otherPosts = data.allGhostPost.edges;

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <header className="post-full-header">
                            <h1 className="content-title">{post.title}</h1>

                            { post.custom_excerpt &&
                            <p className="post-full-custom-excerpt">{post.custom_excerpt}</p>
                            }

                            <div className={byLineClass}>
                                <section className="post-full-byline-content">
                                    <div className="post-card-avatar">
                                        {post.primary_author.profile_image ?
                                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                                            <img className="default-avatar" src={config.siteUrl + '/images/icons/avatar.svg'} alt={post.primary_author.name}/>
                                        }
                                    </div>
                                    <section className="post-full-byline-meta">
                                        <h4 className="author-name">
                                            {post.primary_author.name}
                                        </h4>
                                        <div className="byline-meta-content">
                                            <time className="byline-meta-date" dateTime={post.published_at}>
                                                {post.published_at_pretty}&nbsp;
                                            </time>
                                            <span className="byline-reading-time"><span className="bull">&bull;</span> {readingTime}</span>
                                        </div>
                                    </section>
                                </section>
                            </div>
                        </header>

                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }

                        { welcomePage ? null :
                            <TableOfContents toc={toc} url={url}/>
                        }

                        <section className="post-full-content">
                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: transformedHtml }}
                            />
                        </section>

                        { welcomePage ? null :
                            <HyvorTalk.Embed websiteId={1539} loadMode="scroll" />
                        }

                        { welcomePage ? null :
                        <div className="post-full-content">
                            <header className="preview-posts-header">
                                More {post.primary_tag.name} articles:
                            </header>
                            <section className="post-feed preview-post-footer">
                                {otherPosts.map(({ node }) => (
                                    // The tag below includes the markup for each post - components/common/PostCard.js
                                    <PostCard key={node.id} post={node} />
                                ))}
                            </section>
                        </div>
                        }
                    </article>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields

            # Transformed html
            childHtmlRehype {
                html
                htmlAst
                tableOfContents
            }
        },
        allGhostPost(
            filter: {slug: { ne: $slug }},
            limit: 2,
            sort: { fields: [published_at], order: [DESC, DESC] }
        ) {
            edges {
                node {
                    title
                    slug
                    feature_image
                    excerpt
                    published_at_pretty: published_at(formatString: "DD MMMM, YYYY")
                    reading_time
                    primary_author {
                        name
                        profile_image
                    }
                }
            }
        }
    }
`
